<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching role data
      </h4>
      <div class="alert-body">
        No role found with this role id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-roles-list'}"
        >
          Role List
        </b-link>
        for other roles.
      </div>
    </b-alert>
    <div v-if="roleData">
      <div class="mt-2 pt-75">
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Role Info: Input Fields -->
          <b-form @reset.prevent="resetForm">
            <b-row>
              <!-- Field: Full Name -->
              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Название"
                  rules="required"
                >
                  <b-form-group
                    label="Название"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="roleData.name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Email -->
              <b-col
                cols="12"
                lg="8"
                md="12"
                sm="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Описание"
                  rules="required"
                >
                  <b-form-group
                    label="Описание"
                    label-for="description"
                  >
                    <b-form-input
                      id="description"
                      v-model="roleData.description"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- PERMISSION TABLE -->
            <b-form-group
              label="Доступ"
              label-for="roles"
            >
              <b-table
                id="roles"
                striped
                responsive
                class="mb-0"
                :items="permissionsData"
              >
                <template #cell(module)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  <b-form-checkbox
                    v-model="permissionsData[data.index][data.field.key]"
                    :checked="data.value"
                    @input="permissionCreater"
                  />
                </template>
              </b-table>
              <small class="text-danger">{{ permerrors[0] }}</small>
            </b-form-group>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSubmit(save)"
            >
              Добавить
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{name: 'apps-roles-list'}"
            >
              Отмена
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </component>
</template>

<script>
import {
  BFormInvalidFeedback, BCard, BAlert, BLink, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCardHeader, BCardTitle, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  reactive,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { required, length, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import navMenuItems from '@/navigation/vertical'
import store from '@/store'
import roleStoreModule from '../roleStoreModule'

export default {
  components: {
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      length,
      min,
    }
  },
  setup() {
    // Use toast
    const toast = useToast()
    const permerrors = ref([])
    const permValid = ref(false)
    const roleData = reactive({
      name: '',
      description: '',
      permissions: [],
    })
    const returnModule = item => {
      const obj = {
        module: item.resource,
        read: false,
        write: false,
        create: false,
        delete: false,
      }
      return obj
    }
    const moduleItems = navMenuItems.filter(item => item.resource).map(returnModule)
    const permissionsData = reactive(moduleItems)

    const ROLE_APP_STORE_MODULE_NAME = 'app-role'

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(roleData)

    const permissionCreater = () => {
      // this.$refs.refFormObserver.reset()
      // resetForm()
      permerrors.value = []
      console.log(permissionsData)
      const permiss = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < permissionsData.length; i++) {
        if (permissionsData[i].read === true) {
          permiss.push({ action: 'read', subject: permissionsData[i].module })
        }
        if (permissionsData[i].write === true) {
          permiss.push({ action: 'write', subject: permissionsData[i].module })
        }
        if (permissionsData[i].create === true) {
          permiss.push({ action: 'create', subject: permissionsData[i].module })
        }
        if (permissionsData[i].delete === true) {
          permiss.push({ action: 'delete', subject: permissionsData[i].module })
        }
      }
      permValid.value = permiss.length > 0
      roleData.permissions = JSON.stringify(permiss)
      console.log(permiss, roleData)
      console.log('roleData', roleData)
    }
    const save = () => {
      if (permValid.value) {
        // console.log(permissionsData)
        roleData.createdAt = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' ')
        roleData.updatedAt = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' ')
        // console.log((roleData))

        store.dispatch('app-role/addRole', roleData)
          .then(response => {
            console.log(response)
            router.replace({ name: 'apps-roles-list' })
              .then(() => {
                toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Успешно',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Вы успешно добавили новый роль доступа.',
                  },
                })
              })
          })
          .catch(error => {
            console.log(error)
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ошибка',
                icon: 'XCircleIcon',
                variant: 'error',
                text: error.response.data.error,
              },
            })
          })
      } else {
        permerrors.value.push('Поле Доступ не может быть пустым, выберите минимум 1 опций')
      }
    }

    return {
      roleData,
      permissionsData,
      save,
      permissionCreater,

      refFormObserver,
      getValidationState,
      resetForm,
      permerrors,
    }
  },
}
</script>

<style>

</style>
